<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('ticket_tracker') + ' - ' + $t('detail')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('ticket_tracker') + ' - ' + $t('detail')" :isFilter="false" />
            </template>
            <b-row>
                <b-col cols="12" md="6">
                    <b-card :header="$t('personal_information')" class="soft mb-5">
                        <b-row>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('name_surname')">
                                            <span
                                                v-if="ticketTrackerData && ticketTrackerData.name && ticketTrackerData.surname">
                                              {{ ticketTrackerData.name + ' ' + ticketTrackerData.surname }}
                                            </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <div class="label-as-input-div">
                                    <label>{{ $t('date_of_birth') }}</label>
                                    <div>{{ ticketTrackerData.birthdate ? this.toLocaleDate(ticketTrackerData.birthdate) : '-' }}</div>
                                </div>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('gender')">
                                            <span v-if="ticketTrackerData && ticketTrackerData.gender">
                                              {{ ticketTrackerData.gender }}
                                            </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('country')">
                                            <span
                                                v-if="ticketTrackerData && ticketTrackerData.country_name">
                                              {{ ticketTrackerData.country_name }}
                                            </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('mobile_number')">
                                            <span
                                                v-if="ticketTrackerData && ticketTrackerData.phone">
                                              {{ ticketTrackerData.phone }}
                                            </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('email_address')">
                                            <span class="text-break"
                                                  v-if="ticketTrackerData && ticketTrackerData.email">
                                              {{ ticketTrackerData.email }}
                                            </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('company_name')">
                                            <span class="text-break"
                                                  v-if="ticketTrackerData && ticketTrackerData.company">
                                              {{ ticketTrackerData.company }}
                                            </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">
                    <b-card :header="$t('ticket_information')" class="soft mb-5">
                        <b-row>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('ticket_type')">
                                    <span class="text-break" v-if="ticketTrackerData && ticketTrackerData.ticket_event_type_name">
                                      {{ ticketTrackerData.ticket_event_type_name }}
                                    </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="12" xl="6">
                                <b-form-group :label="$t('amount')">
                                    <span v-if="ticketTrackerData && ticketTrackerData.price">
                                      {{ ticketTrackerData.price + ' ₺' }}
                                    </span>
                                    <span v-else>-</span>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card v-if="ticketTrackerData.ticket_event_type_id == 3" class="mt-2">
                        <b-card-header class="bg-white">
                            <h5>{{ $t('student_certificate') }}</h5>
                        </b-card-header>
                        <b-card-body>
                            <div class="row">
                                <div class="ticket-card">
                                    <div class="ticket-icon">
                                        <i class="ri-file-text-line"></i>
                                    </div>
                                    <div class="ticket-details">
                                        <p class="name">{{ ticketTrackerData.file.name }}</p>
                                    </div>
                                    <div class="ticket-action">
                                        <button class="download-button" @click="downloadStudentFile">
                                            <div class="ticket-download-icon">
                                                <i class="ri-download-2-fill"></i>
                                            </div>
                                            <span>{{ $t('document_download').toUpper() }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card v-if="ticketTrackerData.status == 'approved'" class="mt-2">
                        <b-card-header class="bg-white">
                            <h5>{{ $t('purchased_tickets') }}</h5>
                        </b-card-header>
                        <b-card-body>
                            <div class="row">
                                <div class="ticket-card">
                                    <div class="ticket-icon">
                                        <i class="ri-coupon-2-fill"></i>
                                    </div>
                                    <div class="ticket-details">
                                        <p class="name">{{ ticketTrackerData.name + ' ' + ticketTrackerData.surname }}</p>
                                        <p class="event">{{ $t('future_ai_summit').toUpper() }}</p>
                                    </div>
                                    <div class="ticket-action">
                                        <button class="download-button" @click="downloadTicket">
                                            <div class="ticket-download-icon">
                                                <i class="ri-download-2-fill"></i>
                                            </div>
                                            <span>{{ $t('download_ticket').toUpper() }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="12" md="6">

                </b-col>
            </b-row>
        </app-layout>
    </div>
</template>
<script>
// Services
import TicketTrackerService from "@/services/TicketTrackerService";


export default {
    components: {
    },
    data() {
        return {
            id: this.$route.params.id,
            ticketTrackerData: {},
            formLoading: false,
        }
    },

    metaInfo() {
        return {
            title: this.$t('ticket_tracker') + ' - ' + this.$t('detail')
        }
    },
    created() {
        this.getTickerTrackerData();
    },
    methods: {
        getTickerTrackerData() {
            TicketTrackerService.get(this.id)
                .then(response => {
                    this.ticketTrackerData = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(e.data.message)
                })

        },
        downloadTicket(){
            TicketTrackerService.downloadTicket(this.id)
                .then(response => {
                    this._downloadFile(response, this.$t(`${this.ticketTrackerData.name} ${this.ticketTrackerData.surname}.pdf`))
                })
                .catch(e => {
                    this.$toast.error(e.data.message)
                })
        },
        downloadStudentFile(){
            TicketTrackerService.downloadFile(this.ticketTrackerData.file.uuid)
                .then(response => {
                    this._downloadFile(response, this.$t(`${this.ticketTrackerData.file.file_name}.pdf`))
                })
                .catch(e => {
                    this.$toast.error(e.data.message)
                })
        }
    },
};
</script>

<style scoped>
.ticket-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
    transition: box-shadow 0.3s ease;
}

.ticket-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ticket-icon i {
    font-size: 60px;
    color: #f653fb
}

.ticket-details {
    flex: 1;
    margin: 0 20px;
    min-width: 200px;
}

.ticket-details .name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.ticket-details .event {
    font-size: 14px;
    color: #555;
}

.ticket-action {
    flex-shrink: 0;
    margin-top: auto;
    text-align: center;
}

.download-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    color: #555;
    padding: 0;
    max-width: 100%;
}

.ticket-download-icon i {
    font-size: 30px;
    color: grey;
}

.download-button:hover i {
    color: #000;
}

.download-button:hover span {
    color: #000;
}
</style>
